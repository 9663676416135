import React, { Fragment } from 'react';
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import About from "../components/About/about-us-details";
import BrandLogo from "../components/BrandLogo";
import Funfact from "../components/Funfact";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";

const PageAbout = () => {
    document.title = 'About Us';
    document.querySelector('meta[name="description"]').setAttribute('content', 'tech ojas llp is one of the best and most well-known for cross-platforms mobile app, website, software development company in the world.');
    document.querySelector('meta[name="keywords"]').setAttribute('content', 'Software development company, software development companies, Company that develops software and websites, Web Apps, Android Apps, iPhone Apps, Best iOS App Development Company, Website building agency, Looking for web developer, Application Maintenance Service Provider, Responsive Website Development Services, Website Optimization,Applications for iOS, Android, and Windows, as well as cross-platform apps, custom web development application, mobile application development company');

    return (
        <Fragment>
            <Header />
            <PageHeader
                bgImg={require('../assets/img/page-header.jpg')}
                title="ABOUT US"
                content="Tech Ojas is your bridge to the digital future. We provide expert guidance and cost-effective solutions to fuel your growth."
            />
            <About />
            <BrandLogo />
            <Funfact classes="sp-top" />
            <CallToAction />
            <Footer />
            <MobileMenu />
        </Fragment>
    );
};

export default PageAbout;