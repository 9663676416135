import React from 'react';
import parse from "html-react-parser";
import aboutData from "../../../data/About/about-us-details";

const About = () => {
    return (
        <div className="home-two-about-area">
            <div className="container">
            <div className="row align-items-center">
                    <div className="col-12 col-lg-6">
                        <figure className="about-thumb">
                            <img src={require('../../../assets/img/' + aboutData.thumb)} alt="TOLLP-About"/>
                        </figure>
                    </div>

                    <div className="col-lg-6">
                        <div className="about-content about-content--2">
                            <h6>{aboutData.title}</h6>
                            <h2>{parse(aboutData.heading)}</h2>
                            <span className="about-since">{aboutData.since}</span>
                            <p>{parse(aboutData.text)}</p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="about-content about-content--2">
                            {
                            <div dangerouslySetInnerHTML={{__html: aboutData.textDetails}}/>
                        }
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <figure className="about-thumb">
                            <img src={require('../../../assets/img/' + aboutData.thumb)} alt="Vision-About"/>
                        </figure>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-12 col-lg-6">
                        <figure className="about-thumb">
                            <img src={require('../../../assets/img/' + aboutData.thumb)} alt="Mission-About"/>
                        </figure>
                    </div>
                    <div className="col-lg-6">
                        <div className="about-content about-content--2">
                            {
                            <div dangerouslySetInnerHTML={{__html: aboutData.textCoreValues}}/>
                        }
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-12">
                        <div className="about-content about-content--2">
                            {
                            <div dangerouslySetInnerHTML={{__html: aboutData.textSpectrum}}/>
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
