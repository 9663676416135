import React from 'react';
import From from "./From";
import ContactInfo from "./ContactInfo";
import PageHeader from "../../components/PageHeader";

const ContactPage = () => {
    document.title = 'Get In Touch | Tech Ojas LLP';
    document.querySelector('meta[name="description"]').setAttribute('content', 'tech ojas llp is one of the best and most well-known for cross-platforms mobile app, website, software development company in the world.');
    document.querySelector('meta[name="keywords"]').setAttribute('content', 'Software development company, software development companies, Company that develops software and websites, Web Apps, Android Apps, iPhone Apps, Best iOS App Development Company, Website building agency, Looking for web developer, Application Maintenance Service Provider, Responsive Website Development Services, Website Optimization,Applications for iOS, Android, and Windows, as well as cross-platform apps, custom web development application, mobile application development company');
    return (
        <div className={'contact-page-area-wrapper'}>
            <PageHeader
                bgImg={require('../../assets/img/page-header.jpg')}
                title={'Get In Touch'}
                content={'We are happy to answer any questions you have, explore potential partnerships, and receive requests from interested affiliates.'}
            />
            <div className="container sp-bottom">
                <div className="contact-content-wrap sm-top">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="contact-form-area contact-method">
                                <h3>Send us a Message</h3>
                                <From />
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="contact-information contact-method">
                                <div className="contact-info-con">
                                    <h3>Contact Info</h3>
                                    <ContactInfo
                                        address="Tapovan Shopping Complex, Somalwada, Wardha Road,
                                        Nagpur, Maharashtra 440025 <br />
                                        +91 9422 883 052<br/>
                                        info@techojasllp.com"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactPage;