import React, { useRef, useState } from 'react';
import FormInput from "../../components/UI/Input";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from '@emailjs/browser';

const From = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [message, setMessage] = useState('');
    // const [errorMessage, setErrorMessage] = useState('');
    const recaptchaRef = useRef();

    const handleSubmit = (e) => {
        e.preventDefault();
        const token = recaptchaRef.current.getValue();

        const templateParams = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            message: message,
            phoneNo: phoneNo,
            "g-recaptcha-response": token,
        };

        emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
            templateParams,
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID)
            .then((response) => {
                console.log('Email sent successfully!', response);
                alert(`Thank you for your email ${firstName}!  I will get back to you soon!`);
                window.location.reload();
            })
            .catch((error) => {
                console.error('Error:', error)
            })
    }
    return (
        <div className="contact-form-wrap">
            <form onSubmit={handleSubmit} id="contact-form">
                <div className="row">
                    <div className="col-md-6">
                        <FormInput
                            tag={'input'}
                            type={'text'}
                            name={'first_name'}
                            placeholder={'First Name *'}
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </div>

                    <div className="col-md-6">
                        <FormInput
                            tag={'input'}
                            type={'text'}
                            name={'last_name'}
                            placeholder={'Last Name *'}
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </div>

                    <div className="col-md-6">
                        <FormInput
                            tag={'input'}
                            type={'email'}
                            name={'email_address'}
                            placeholder={'Email address *'}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>

                    <div className="col-md-6">
                        <FormInput
                            tag={'input'}
                            type={'text'}
                            name={'phone_no'}
                            placeholder={'Phone No *'}
                            value={phoneNo}
                            onChange={(e) => setPhoneNo(e.target.value)}
                        />
                    </div>

                    <div className="col-12">
                        <FormInput
                            tag={'textarea'}
                            name={'con_message'}
                            placeholder={'Write Your Message *'}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                    </div>
                    <div className="col-6">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey="6LdNKtApAAAAAJ1ZYwYHevreySIvQd9YI8kpQ358"
                        />

                        <FormInput
                            tag={'button'}
                            classes={'mt-4'}
                        />

                        <div className="form-message" />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default From;