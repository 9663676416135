import React,{Fragment} from 'react';
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import PageAbout from "../components/About/page";
import Services from "../components/Services";
import BrandLogo from "../components/BrandLogo";
import Funfact from "../components/Funfact";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";

import ServiceThumb from '../assets/img/about.jpg'

const PageService = () => {
    document.title = 'OUR SERVICES';
    document.querySelector('meta[name="description"]').setAttribute('content', 'tech ojas llp is one of the best and most well-known for cross-platforms mobile app, website, software development company in the world.');
    document.querySelector('meta[name="keywords"]').setAttribute('content', 'Software development company, software development companies, Company that develops software and websites, Web Apps, Android Apps, iPhone Apps, Best iOS App Development Company, Website building agency, Looking for web developer, Application Maintenance Service Provider, Responsive Website Development Services, Website Optimization,Applications for iOS, Android, and Windows, as well as cross-platform apps, custom web development application, mobile application development company');
    
    return (
        <Fragment>
            <Header/>
            <PageHeader
                bgImg={require('../assets/img/page-header.jpg')}
                title="OUR SERVICES"
                content="Explore our comprehensive IT services designed to propel you towards success."
            />
            <PageAbout
                title={'Our Services'}
                heading="Provide best <br/> Business Solutions"
                thumb={ServiceThumb}
                content="<b>Businex</b> always try to provide the best Business Solutions for Clinets to grow up their Business very sharply and smoothly. We voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt."
            />
            <Services classes="sm-top"/>
            <BrandLogo/>
            <Funfact classes="sp-top"/>
            <CallToAction/>
            <Footer/>
            <MobileMenu/>
        </Fragment>
    );
};

export default PageService;