import React,{Fragment} from 'react';
import PageHeader from "../../components/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import ServiceContentWrap from "./ServiceContentWrap";

import ServicesData from "../../data/Services/services";

const ServiceDetails = () => {
    const serviceID = new URLSearchParams(window.location.search).get("id");
    const service = ServicesData.find((serviceItem) => serviceItem.id === parseInt(serviceID));
    document.title = service.title + ' | Tech Ojas LLP';
    document.querySelector('meta[name="description"]').setAttribute('content', service.metaDescription);
    document.querySelector('meta[name="keywords"]').setAttribute('content', service.metaKeywords);

    return (
        <Fragment>
            <PageHeader
                bgImg={require('../../assets/img/page-header.jpg')}
                title={service.title}
                content={service.shortDesc}
            />

            <PageWrapper classes="sm-top service-details-wrapper">
                <ServiceContentWrap
                    service={service}
                />

            </PageWrapper>

        </Fragment>
    );
};

export default ServiceDetails;