import React,{Fragment} from 'react';
import PageWrapper from "../../components/PageWrapper";
import BlogDetailsContentWrapper from "./ContentWrapper";
import PageHeader from "../../components/PageHeader";
import BlogData from "../../data/Blog/blog";

const BlogDetailsPage = ({sidebar}) => {
    const post_id = new URLSearchParams(window.location.search).get("id");
    const post = BlogData.find(post => post.id === parseInt(post_id));
    document.title = post.title + ' | Tech Ojas LLP';
    document.querySelector('meta[name="description"]').setAttribute('content', 'Dive deeper with insightful content from the Tech Ojas blog. Explore industry trends, practical tips, and expert advice to elevate your business and achieve your goals.');
    document.querySelector('meta[name="keywords"]').setAttribute('content', 'blog, blogging, content marketing, content creation, inbound marketing, online marketing, digital marketing, marketing strategy, business development, audience engagement, content strategy');

    return (
        <Fragment>
            <PageHeader
                bgImg={require('../../assets/img/page-header.jpg')}
                title={post.title}
                content={post.excerpt}
            />

            <PageWrapper classes={'blog-details-page-content sp-y'}>
                <div className={!sidebar ? 'col-12' : 'col-lg-9'}>
                    <BlogDetailsContentWrapper sidebar={sidebar} post={post}/>
                </div>

            </PageWrapper>
        </Fragment>
    );
};

export default BlogDetailsPage;