import React, { Fragment } from 'react';
import PageWrapper from "../../components/PageWrapper";
import PageHeader from "../../components/PageHeader";
import BlogContent from "./BlogContent";

const BlogPage = ({ sidebar_position, blog_type, sidebar }) => {
    document.title = 'Blogs | Tech Ojas LLP';
    document.querySelector('meta[name="description"]').setAttribute('content', 'Dive deeper with insightful content from the Tech Ojas blog. Explore industry trends, practical tips, and expert advice to elevate your business and achieve your goals.');
    document.querySelector('meta[name="keywords"]').setAttribute('content', 'blog, blogging, content marketing, content creation, inbound marketing, online marketing, digital marketing, marketing strategy, business development, audience engagement, content strategy');
    return (
        <Fragment>
            <PageHeader
                bgImg={require('../../assets/img/page-header.jpg')}
                title={'From Tech Ojas Latest'}
                content={'Stay ahead of the curve. Explore our latest tech insights and thought leadership on the Tech Ojas blog.'}
            />

            <PageWrapper classes={'blog-page-content-area sp-y'}>

                <BlogContent blog_type={blog_type} cols={sidebar ? 'col-lg-9' : 'col-12'} classes={sidebar_position === 'left' ? 'order-0 order-lg-1' : null} />

            </PageWrapper>
        </Fragment>
    );
};

export default BlogPage;